import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProductLayout from "../../components/layouts/product_layout"
import {
    ProductButtons,
    ProductParagraph,
    ProductScreenShot,
    ProductBullets,
    ResourceTeaser,
    ProductTestimonials,
} from "../../components/shared"
import ProductDemoForm from "../../components/products/product_demo_form"

export default function Telequeue({ location }) {
    const data = useStaticQuery(graphql`
        query {
            productsYaml(name: { eq: "telequeue" }) {
                title
                description
                app_cloud_link
                copy {
                    tagline
                    paragraph1
                    bullets {
                        header
                        description
                    }
                    testimonials {
                        quote
                        client
                    }
                    demo_message
                }
                rating
                ratingCount
            }
            productLogo: file(
                relativePath: { eq: "images/products/telequeue/logo.png" }
            ) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            screenShots: allFile(
                filter: {
                    relativeDirectory: {
                        eq: "images/products/telequeue/screenshots"
                    }
                }
            ) {
                nodes {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            whitepaperThumbnail: file(
                relativePath: {
                    eq: "images/resources/whitepapers/why-waiting-for-sales-to-qualify-is-killing-conversion-rates.jpg"
                }
            ) {
                childImageSharp {
                    fixed(height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const {
        productsYaml: {
            title,
            description,
            app_cloud_link,
            copy: { tagline, paragraph1, bullets, testimonials, demo_message },
            rating,
            ratingCount,
        },
        productLogo: {
            childImageSharp: { fixed: logoFixed },
        },
        screenShots: { nodes: screenShots },
        whitepaperThumbnail: {
            childImageSharp: { fixed: whitepaperThumbnailFixed },
        },
    } = data

    return (
        <ProductLayout
            title={title}
            description={description}
            location={location}
            tagline={tagline}
            logo={logoFixed}
            rating={rating}
            ratingCount={ratingCount}
        >
            <div className="mt-8">
                <ProductParagraph content={paragraph1} />
            </div>

            <ProductScreenShot fluid={screenShots[3].childImageSharp.fluid} />

            <ProductButtons appCloudLink={app_cloud_link} />

            <ResourceTeaser
                thumbnail={whitepaperThumbnailFixed}
                content={`Check out our white paper <u>"Why waiting for sales to
                        qualify your marketing inquiries is <i>killing</i>
                        conversion rates"</u> to learn about how Tele-Qualification
                        can help increase Eloqua ROI and sales acceptance rates.`}
                href="/whitepapers/why-waiting-for-sales-to-qualify-is-killing-conversion-rates"
                buttonText="Download Now"
            />

            <div className="mt-12">
                <ProductBullets bullets={bullets.slice(0, 3)} />
            </div>

            <ProductScreenShot fluid={screenShots[1].childImageSharp.fluid} />

            <div className="mt-12">
                <ProductBullets bullets={bullets.slice(3, 5)} />
            </div>

            <ProductScreenShot fluid={screenShots[2].childImageSharp.fluid} />

            <div className="mt-12">
                <ProductBullets bullets={bullets.slice(5)} />
            </div>

            <ProductScreenShot fluid={screenShots[0].childImageSharp.fluid} />

            <ProductTestimonials testimonials={testimonials} />

            <ProductDemoForm formMessage={demo_message} />
        </ProductLayout>
    )
}
